import React, { useContext } from 'react';
import 'twin.macro';
import { FieldContext } from '@leuven2030/framework/Field/FieldProvider';

const FieldLabel = (props) => {
  const context = useContext(FieldContext);
  const title = context.title || props.title;
  const description = context.description || props.description;
  if (!title && !description) {
    return <></>;
  }
  return (
    <div {...props}>
      {!!title && (
        <label tw="block text-sm font-bold leading-5 text-gray-600 text-left">
          <h3>{title}</h3>
        </label>
      )}
      {!!description && (
        <div tw="text-sm leading-5 text-gray-500 max-w-sm">{description}</div>
      )}
    </div>
  );
};

export default FieldLabel;
