import 'twin.macro';
import React, { useContext } from 'react';
import FieldPassword from '@leuven2030/framework/Field/FieldPassword';
import { AuthMessage } from '@leuven2030/framework/Auth/AuthMessage';
import Button from '@leuven2030/framework/Button/Button';
import { useFormikContext } from 'formik';
import {
  AuthSigninContext,
  AuthSigninView
} from '@leuven2030/framework/Auth/AuthSignin';
import UserAvatar from '@leuven2030/framework/User/UserAvatar';

const AuthPassword = () => {
  const { submitForm, isSubmitting, values, errors, isValid } =
    useFormikContext();
  const { view, setView } = useContext(AuthSigninContext);
  if (view !== AuthSigninView.PASSWORD) {
    return <></>;
  }
  return (
    <div tw="space-y-3">
      <div tw="flex items-center justify-between space-x-3">
        <div tw="flex items-center space-x-3">
          <UserAvatar tw="bg-gray-100 w-10 h-10" />
          <p>{values.email}</p>
        </div>
        <Button
          onClick={() => {
            setView(AuthSigninView.DEFAULT);
          }}
        >
          Change
        </Button>
      </div>
      <FieldPassword autoFocus />
      <div tw="mt-6 flex items-center justify-end">
        <div tw="text-sm leading-5">
          <a
            href="#"
            tw="font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            Forgot your password?
          </a>
        </div>
      </div>
      <AuthMessage />
      <Button
        disabled={!isValid}
        variant="contained"
        loading={isSubmitting}
        onClick={() => {
          submitForm();
        }}
        tw="mt-6 w-full"
        color="primary"
      >
        Sign in
      </Button>
    </div>
  );
};

export default AuthPassword;
