import React, { useContext } from 'react';
import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import { AuthState, ChallengeName } from '@leuven2030/framework/Auth/AuthTypes';
import isEmpty from 'lodash/isEmpty';
import { Auth } from '@aws-amplify/auth';
import 'twin.macro';
import UserContext from '@leuven2030/framework/User/UserContext';
import Form from '@leuven2030/framework/Form/Form';
import * as Yup from 'yup';

export const AuthCredentialsSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .max(250)
    .minLowercase(1)
    .minUppercase(1)
    .minNumbers(1)
    .minSymbols(1),
  email: Yup.string().email('Invalid email').required('Required')
});

const AuthSigninForm = ({ children }) => {
  const { setErrorMessage, setUser, setAuthState } = useContext(AuthContext);
  const { reloadUserInfo } = useContext(UserContext);
  const initialValues = {
    email: '',
    password: ''
  };

  return (
    <Form
      validationSchema={AuthCredentialsSchema}
      onSubmit={async (credentials) => {
        await signIn(credentials);
      }}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );

  async function signIn(credentials) {
    const { email, password } = credentials;
    if (!email) {
      return;
    }
    if (!password) {
      return;
    }
    setUser(credentials);
    const response = await Auth.signIn(email, password).catch((error) => {
      setErrorMessage(error.message);
      if (error.code === 'UserNotConfirmedException') {
        setAuthState(AuthState.ConfirmSignUp);
      } else if (error.code === 'PasswordResetRequiredException') {
        setAuthState(AuthState.ForgotPassword);
      }
      return { error };
    });
    if (!response.error) {
      switch (response.challengeName) {
        case ChallengeName.SMSMFA:
        case ChallengeName.SoftwareTokenMFA:
          setAuthState(AuthState.ConfirmSignIn);
          break;
        case ChallengeName.NewPasswordRequired:
          setAuthState(AuthState.ResetPassword);
        case ChallengeName.MFASetup:
        case ChallengeName.TOTPSetup:
          setAuthState(AuthState.TOTPSetup);
        default:
          checkContact(response);
          break;
      }
    }
  }

  function checkContact(user) {
    Auth.verifiedContact(user).then((data) => {
      if (isEmpty(data.verified)) {
        user = Object.assign(user, data);
        setAuthState(AuthState.VerifyContact);
      } else {
        reloadUserInfo();
      }
    });
  }
};

export default AuthSigninForm;
