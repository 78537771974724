import ImgixClient from '@imgix/js-core';
import URL from 'url-parse';
import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import isNull from 'lodash/isNull';
import pickBy from 'lodash/pickBy';
const ImageURL = (path, params) => {
  if (!path) {
    return;
  }
  const srcParsed = new URL(path);
  const { host: domain, protocol, origin, pathname } = srcParsed;
  if (!domain) {
    return;
  }
  const client = new ImgixClient({
    domain,
    includeLibraryParam: false,
    useHTTPS: protocol == 'https:'
  });
  const url = client.buildURL(pathname, {
    auto: 'compress,format',
    ...pickBy(params, (value) => {
      return !isNull(value);
    })
  });
  const { query } = new URL(url);
  return `${origin}${pathname}${query}`;
};

export const useImageUrl = ({ src, imgixParams, defaultParams }) => {
  const [value, setValue] = useState(getValue());

  useEffect(() => {
    setValue(getValue());
  }, [imgixParams]);

  function getValue() {
    const params = omit({ ...defaultParams, ...imgixParams }, [
      'txt64',
      'mark64'
    ]);

    return {
      fallbackUrl: ImageURL(src, {
        ...params,
        blur: 400,
        w: (params.w || 100) / 5,
        h: (params.h || 100) / 5
      }),
      imageUrl: ImageURL(src, imgixParams)
    };
  }

  return value;
};

export default ImageURL;
