import React, { createContext, useContext, useState } from 'react';
import { AuthState } from '@leuven2030/framework/Auth/AuthTypes';
import tw from 'twin.macro';
import Button from '@leuven2030/framework/Button/Button';
import AuthSigninSocial from '@leuven2030/framework/Auth/AuthSigninSocial';
import AuthEmail from '@leuven2030/framework/Auth/AuthEmail';
import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import AuthOption from '@leuven2030/framework/Auth/AuthOption';
import AuthPassword from '@leuven2030/framework/Auth/AuthPassword';
import AuthSigninForm from '@leuven2030/framework/Auth/AuthSigninForm';
export enum AuthSigninView {
  DEFAULT = 'DEFAULT',
  PASSWORD = 'PASSWORD'
}
export const AuthSigninContext = createContext({
  view: AuthSigninView.DEFAULT,
  setView: (value) => null
});
export const AuthSignin = () => {
  const { setAuthState } = useContext(AuthContext);
  const [view, setView] = useState(AuthSigninView.DEFAULT);
  return (
    <AuthSigninContext.Provider value={{ view, setView }}>
      <div tw="divide-y">
        <AuthSigninForm>
          <div
            tw="space-y-6 pb-6 hidden"
            css={[view == AuthSigninView.DEFAULT && tw`block`]}
          >
            <AuthOption title="Get started with">
              <AuthSigninSocial />
            </AuthOption>
            <AuthOption title="or by email">
              <AuthEmail />
            </AuthOption>
          </div>
          <AuthPassword />
        </AuthSigninForm>

        <div tw="flex items-center justify-end py-6">
          <span tw="text-xs mr-3">{`Don't have an account?`}</span>
          <Button
            variant="contained"
            size="small"
            onClick={() => setAuthState(AuthState.SignUp)}
          >
            Sign up
          </Button>
        </div>
      </div>
    </AuthSigninContext.Provider>
  );
};

export default AuthSignin;
