import { Auth } from '@aws-amplify/auth';

const UserExists = async (email: string) => {
  const credentials = {
    username: email,
    password: 'aaAA!!000000000'
  };
  return await Auth.signUp(credentials)
    .then((data) => {
      //console.log(data);
      return false;
    })
    .catch((error) => {
      //The user has already registered so go to the SignIn method
      if (error['code'] === 'UsernameExistsException') {
        return true;
      } else {
        //console.log(error);
        return false;
      }
    });
};

export default UserExists;
