import { Auth } from '@aws-amplify/auth';
import React, { useContext } from 'react';
import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import { AuthMessage } from '@leuven2030/framework/Auth/AuthMessage';
import { AuthSigninSocial } from '@leuven2030/framework/Auth/AuthSigninSocial';
import { AuthState } from '@leuven2030/framework/Auth/AuthTypes';
import { AuthTitle } from '@leuven2030/framework/Auth/AuthTitle';
import 'twin.macro';

import { useFormikContext } from 'formik';
import Button from '@leuven2030/framework/Button/Button';
import Form from '@leuven2030/framework/Form/Form';
import FieldPassword from '@leuven2030/framework/Field/FieldPassword';
import FieldEmail from '@leuven2030/framework/Field/FieldEmail';
import { AuthCredentialsSchema } from '@leuven2030/framework/Auth/AuthSigninForm';

export const AuthSignup = () => {
  const socialLoginEnabled = false;

  const initialValues = {
    email: '',
    password: ''
  };
  const { setAuthState, setErrorMessage, setSuccessMessage, setUser } =
    useContext(AuthContext);
  const signUp = async ({ email, password }: typeof initialValues) => {
    const user = await Auth.signUp({
      username: email,
      password
    }).catch((error) => {
      setErrorMessage(error.message);
    });
    if (user) {
      setUser({
        email,
        password
      });
      setAuthState(AuthState.ConfirmSignUp);
      setSuccessMessage(
        'You have successfully signed up. Enter the verification code we just sent you by email.'
      );
    }
  };

  // async function confirmSignUp({ email, code }) {
  //   try {
  //     await Auth.confirmSignUp(email, code);
  //   } catch (error) {
  //     console.log('error confirming sign up', error);
  //   }
  // }

  return (
    <div>
      <AuthTitle title="Your new account" />
      <div tw="mt-8">
        {socialLoginEnabled && (
          <div>
            <div>
              <p tw="text-sm leading-5 font-medium text-gray-700">
                Sign up with
              </p>

              <AuthSigninSocial />
            </div>

            <div tw="mt-6 relative">
              <div tw="absolute inset-0 flex items-center">
                <div tw="w-full border-t border-gray-300" />
              </div>
              <div tw="relative flex justify-center text-sm leading-5">
                <span tw="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>
          </div>
        )}

        <Form
          onSubmit={signUp}
          initialValues={initialValues}
          validationSchema={AuthCredentialsSchema}
          tw="mt-6"
          schemaName="UserCredentials"
        >
          <FormContent />
        </Form>

        <div tw="flex items-center mt-4 justify-end">
          <span tw="text-xs mr-3">Already have an account?</span>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setAuthState(AuthState.SignIn);
            }}
          >
            Sign in
          </Button>
        </div>
      </div>
    </div>
  );
};

const FormContent = () => {
  const { isSubmitting } = useFormikContext();
  return (
    <>
      <FieldEmail />
      <FieldPassword tw="mt-4" />
      <AuthMessage />
      <Button
        tw="mt-6 w-full"
        color="primary"
        variant="contained"
        type="submit"
        loading={isSubmitting}
      >
        Sign up
      </Button>
    </>
  );
};

export default AuthSignup;
