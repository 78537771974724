import React, { useContext, useState } from 'react';
import 'twin.macro';
import Button from '@leuven2030/framework/Button/Button';
import FieldText from '@leuven2030/framework/Field/FieldText';
import {
  AuthSigninContext,
  AuthSigninView
} from '@leuven2030/framework/Auth/AuthSignin';
import UserExists from '@leuven2030/framework/User/UserExists';
import { useField, useFormikContext } from 'formik';
import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import { AuthState } from '@leuven2030/framework/Auth/AuthTypes';
import FieldEmail from '@leuven2030/framework/Field/FieldEmail';
import { useKey } from 'react-use';

const AuthEmail = () => {
  const { view, setView } = useContext(AuthSigninContext);
  const { setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { values } = useFormikContext();
  const [input, field] = useField('email');
  const isInvalid = !!field.error || !field.value;

  useKey(
    'Enter',
    () => {
      next();
    },
    {},
    [field, setView, setAuthState, setLoading]
  );
  if (view != AuthSigninView.DEFAULT) {
    return <></>;
  }

  async function next() {
    if (isInvalid) {
      return;
    }
    setLoading(true);
    const exists = await UserExists(values.email);
    if (exists) {
      setView(AuthSigninView.PASSWORD);
    } else {
      setAuthState && setAuthState(AuthState.SignUp);
    }
    setLoading(false);
  }

  return (
    <div>
      <FieldEmail
        disabled={loading}
        title={null}
        placeholder="Your email address..."
        inputProps={{
          onKeyPress: (e) => {
            if (e.key === 'Enter') {
              next();
            }
          }
        }}
      />
      <Button
        disabled={isInvalid}
        variant="contained"
        loading={loading}
        tw="mt-6 w-full"
        color="primary"
        onClick={next}
      >
        Continue
      </Button>
    </div>
  );
};

export default AuthEmail;
