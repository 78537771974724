import IconMicrosoft from '@leuven2030/framework/Icon/IconMicrosoft';
import React from 'react';
import 'twin.macro';
import { Auth } from '@aws-amplify/auth';

const SocialAuthButton = ({ Icon, title, ...props }) => (
  <div
    tw="py-2 px-2 border border-gray-300 flex items-center rounded space-x-2 justify-center cursor-pointer"
    aria-label="Sign in with..."
    {...props}
  >
    <Icon tw="w-6 h-6" />
    <span>{title}</span>
  </div>
);

export const AuthSigninSocial = () => (
  <div tw="mt-1 grid grid-cols-1 gap-3">
    <SocialAuthButton
      Icon={IconMicrosoft}
      title="Microsoft"
      onClick={() => {
        Auth.federatedSignIn({
          customProvider: 'Microsoft'
        });
      }}
    />
  </div>
);

export default AuthSigninSocial;
