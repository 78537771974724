import BrandLogo from '@leuven2030/framework/Brand/BrandLogo';
import React from 'react';
import 'twin.macro';

const AuthHeader = () => {
  return (
    <div tw="space-x-3 py-6 flex items-center">
      <BrandLogo tw="h-8 w-auto  " />
      <div>
        <h2 tw="uppercase text-base text-gray-400 font-medium leading-none">
          Single sign-on
        </h2>
        <span tw="text-primary-600">read more</span>
      </div>
    </div>
  );
};

export default AuthHeader;
