import React, { FC, useContext } from 'react';
import 'twin.macro';
import FieldContainer from '@leuven2030/framework/Field/FieldContainer';
import Field from '@leuven2030/framework/Field/Field';
import {
  FieldContext,
  FieldProps
} from '@leuven2030/framework/Field/FieldProvider';
import FieldInput from '@leuven2030/framework/Field/FieldInput';
import { useField, useFormikContext } from 'formik';

const FieldComponent = ({
  className,
  field,
  form,
  inputProps,
  children,
  ...rest
}) => {
  const { name } = useContext(FieldContext);
  const { handleChange } = useFormikContext();
  const [{ value }] = useField(name);
  return (
    <FieldContainer className={className}>
      <FieldInput name={name} value={value} onChange={handleChange} {...rest} />
    </FieldContainer>
  );
};

const FieldText: FC<FieldProps> = (props) => {
  return <Field component={FieldComponent} {...props} />;
};

export default FieldText;
