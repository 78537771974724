import { Auth } from '@aws-amplify/auth';
import React, { useContext, useState } from 'react';
import { AuthContext } from '@leuven2030/framework/Auth/Auth';
import { AuthState } from '@leuven2030/framework/Auth/AuthTypes';
import FieldText from '@leuven2030/framework/Field/FieldText';
import Button from '@leuven2030/framework/Button/Button';
import UserContext from '@leuven2030/framework/User/UserContext';
import Form from '@leuven2030/framework/Form/Form';
import 'twin.macro';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { AuthMessage } from '@leuven2030/framework/Auth/AuthMessage';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
export const AuthVerificationCodeSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^\d+$/, 'The field should have digits only')
    .required()
    .length(6)
});

export const AuthConfirmSignup = () => {
  const [resending, setResending] = useState(false);
  const {
    setErrorMessage,
    setSuccessMessage,
    setMessage,
    setAuthState,
    user: userData
  } = useContext(AuthContext);
  const { reloadUserInfo } = useContext(UserContext);

  const initialValues = {
    code: ''
  };

  return (
    <div tw="space-y-6">
      <Form
        validationSchema={AuthVerificationCodeSchema}
        onSubmit={confirmSignUp}
        initialValues={initialValues}
        tw="flex flex-col space-y-3"
      >
        <AuthMessage />
        <FieldText title="Verification code" name="code" type="text" />

        <AuthConfirmButton />
        <Button
          variant="outlined"
          onClick={resendConfirmCode}
          loading={resending}
        >
          Resend code
        </Button>
      </Form>
      <Button
        startIcon={<NavigateBeforeIcon />}
        onClick={() => {
          setAuthState(AuthState.SignIn);
        }}
      >
        Back to sign in
      </Button>
    </div>
  );

  async function confirmSignUp(form: typeof initialValues) {
    setMessage(null);
    const response = await Auth.confirmSignUp(userData.email, form.code).catch(
      (error) => {
        setErrorMessage(error.message);
        return {
          error
        };
      }
    );
    if (!response.error && userData.email && userData.password) {
      await Auth.signIn(userData.email, userData.password);
      await reloadUserInfo();
    }
  }

  async function resendConfirmCode() {
    setResending(true);
    setMessage(null);
    if (!userData.email) throw new Error('Username can not be empty');
    await Auth.resendSignUp(userData.email).catch((error) => {
      setErrorMessage(error.message);
    });
    setAuthState(AuthState.ConfirmSignUp);
    setSuccessMessage(
      `The verification code was sent to your email: ${userData.email}`
    );
    setResending(false);
  }
};

const AuthConfirmButton = () => {
  const { isValid, isSubmitting } = useFormikContext();
  return (
    <Button
      tw="w-full"
      color="primary"
      type="submit"
      disabled={!isValid}
      variant="contained"
      loading={isSubmitting}
    >
      Confirm
    </Button>
  );
};

export default AuthConfirmSignup;
