import FieldLabel from '@leuven2030/framework/Field/FieldLabel';
import React, { Fragment, FC, useContext, useEffect, useState } from 'react';
import 'twin.macro';
import { FieldContext } from '@leuven2030/framework/Field/FieldProvider';
import Collapse from '@material-ui/core/Collapse';
import { FieldState } from '@leuven2030/framework/Field/FieldContainer';
import isObject from 'lodash/isObject';
import values from 'lodash/values';
const FieldContainerBase: FC<Partial<FieldState>> = ({
  error,
  touched,
  isValid,
  children,
  ...rest
}) => {
  const { hideFeedback } = useContext(FieldContext);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    setErrors(getErrors());
  }, [error]);
  function getErrors() {
    if (!error) {
      return [];
    }
    if (isObject(error)) {
      return values(error);
    }
    return [error];
  }
  return (
    <div tw="flex flex-col" {...rest}>
      <FieldLabel tw="text-gray-500 mb-2" />
      {children}
      <Collapse in={!hideFeedback && !!error}>
        <p tw="text-sm text-red-600 mt-2">
          {errors.map((error, index) => (
            <Fragment key={error}>
              <span tw="">{error}</span>
              {!!(errors.length > 1 && index < errors.length - 1) && (
                <span>, </span>
              )}
            </Fragment>
          ))}
        </p>
      </Collapse>
    </div>
  );
};
export default FieldContainerBase;
