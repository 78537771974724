import React from 'react';
import 'twin.macro';

const AuthOption = ({ title, children }) => {
  return (
    <div tw="space-y-2">
      <p tw="text-sm text-gray-800 font-bold">{title}</p>
      {children}
    </div>
  );
};

export default AuthOption;
