import React, { forwardRef, InputHTMLAttributes } from 'react';
import tw from 'twin.macro';
import Input from '@material-ui/core/Input';
import { css } from '@emotion/react';

export const FieldStyle = tw` box-border block w-full border border-gray-200 bg-gray-100 rounded-md focus:border-primary-500 focus:ring-0 focus:ring-opacity-0 sm:text-sm sm:leading-5 p-3 leading-normal`;

const FieldInputForwardRef = (
  { inputRef, inputStyle, value, ...props },
  ref
) => {
  return (
    <Input
      autoComplete="off"
      autoCapitalize="off"
      disableUnderline
      inputRef={ref || inputRef}
      css={[
        FieldStyle,
        {
          padding: 0
        },
        css`
          ${tw`flex items-center`}
          input {
            ${FieldStyle}
            ${tw`bg-transparent border-none`}
            ${inputStyle}
          }
        `
      ]}
      value={value || ''}
      {...props}
    />
  );
};

const FieldInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & { inputRef?: any }
>(FieldInputForwardRef);

export default FieldInput;
