import { Auth } from '@aws-amplify/auth';
import { AuthOptions } from '@aws-amplify/auth/src/types';
import ServerEnv from '@leuven2030/framework/Server/ServerEnv';

const AuthAmplify = () => {
  const options: AuthOptions = {
    userPoolId: process.env.COGNITO_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
    region: process.env.AWS_REGION,
    authenticationFlowType: 'USER_SRP_AUTH',
    cookieStorage: {
      domain: ServerEnv.domain,
      path: '/',
      expires: 365,
      secure: !ServerEnv.isDev
    },
    oauth: {
      domain: process.env.COGNITO_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      // urlOpener: async (url, redirectString) => {
      //   console.log(url, redirectString);
      // },
      options: {
        prompt: 'select_account'
      },
      redirectSignIn: ServerEnv.endpoint,
      redirectSignOut: `https://login.microsoftonline.com/${process.env.MICROSOFT_TENANT_ID}/oauth2/v2.0/logout?post_logout_redirect_uri=${ServerEnv.endpoint}`,
      responseType: 'code'
    }
  };
  Auth.configure(options);
};

export default AuthAmplify;
